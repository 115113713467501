<template>
  <div>
    <div class="mx-auto flex min-h-screen max-w-[2560px] flex-col">
      <TheNavigation :data="globalData?.data.attributes.navigation" />
      <TheBanner v-if="globalData?.data.attributes.banner" class="mt-2" :content="globalData.data.attributes.banner" />
      <div class="flex flex-col gap-4 lg:flex-row">
        <main class="flex flex-col bg-brick-200 lg:min-h-[70vh] lg:w-1/2">
          <div class="mx-auto w-full max-w-xl p-5 md:pt-12 xl:px-0">
            <slot />
          </div>
        </main>
        <aside class="-mt-6 rounded-t-xl bg-white p-5 md:pt-12 lg:md:w-1/2 lg:mt-0 lg:bg-transparent">
          <slot name="aside" />
        </aside>
      </div>
      <TheFooter :data="globalData?.data.attributes.footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from "~/store/global";

const { globalData } = useGlobalStore();
</script>

<style scoped></style>
